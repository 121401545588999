import { SvgIcon } from "@mui/material";
import React from "react";

const PersonIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5394 0C7.64107 0 5.2697 2.95103 5.2697 6.58713C5.2697 10.2232 7.64107 13.1743 10.5394 13.1743C13.4377 13.1743 15.8091 10.2232 15.8091 6.58713C15.8091 2.95103 13.4377 0 10.5394 0ZM5.03257 13.1743C2.23962 13.306 0 15.5983 0 18.444V21.0788H21.0788V18.444C21.0788 15.5983 18.8655 13.306 16.0462 13.1743C14.6234 14.7815 12.6736 15.8091 10.5394 15.8091C8.40518 15.8091 6.45539 14.7815 5.03257 13.1743Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default PersonIcon;
