import { Box } from "@mui/material";
import React from "react";
import MenuBottom from "../menu/MenuBottom";
import RatingView from "../pages/rating/RatingView";
import { useSelector } from "react-redux";
import InfoView from "../pages/info/InfoView";
// @ts-ignore
import cl from "./Mobile.module.scss";
// @ts-ignore
import Profile from "../pages/profile/Profile";
import AddResult from "../pages/addResult/AddResult";

const Mobile = () => {
  const menuSelected = useSelector((state: any) => state.menu.value);

  return (
    <Box className={cl.Mobile}>
      {menuSelected === "rating" && <RatingView />}
      {menuSelected === "info" && <InfoView />}
      {menuSelected === "profile" && <Profile/>}
      {menuSelected === "add" && <AddResult/>}
      <MenuBottom />
    </Box>
  );
};

export default Mobile;
