import React from "react";
import Mobile from "./components/mobile/Mobile";
import { Container } from "@mui/material";
import { Provider } from "react-redux";
import store from "./components/redux";

function App() {
  return (
    <Provider store={store}>
      <Container maxWidth="sm">
        <Mobile />
      </Container>
    </Provider>
  );
}

export default App;
