import {
  Alert,
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
//@ts-ignore
import cl from "./AddResult.module.scss";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import axios from "axios";
import { axiosArray, questions } from "./AddResult.types";

//@ts-ignore
const tg = window.Telegram.WebApp;

const apiKey: string | any = process.env.REACT_APP_API_KEY;

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

let axiosValue: any = [];

const AddResult = () => {
  const [step, setStep] = useState<number>(1);
  const [progress, setProgress] = React.useState(10);
  const [itemForm, setItemForm] = useState<any>({});
  const [selectValue, setSelectValue] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  let resultText: string = `We have added you to the list with statistics, thank you`;

  const sendForm = () => {
    if (inputValue) {
      setError(false);
      axiosValue.push(inputValue);
    } else {
      setError(true);
      return;
    }
    const id = tg.initDataUnsafe?.user.id;
    const formData = new FormData();
    formData.append("chat_id", id);

    for (let i = 0; i < axiosArray.length; i++) {
      formData.append(axiosArray[i], axiosValue[i]);
    }
    axios
      .post(apiKey, formData)
      .then((res) => {
        if (res) {
          setSuccess(true);
          setError(false);
        }
      })
      .catch((e) => {
        console.log(e);
        return;
      });
  };

  const nextStep = () => {
    if (!inputValue && !selectValue && step === 7) {
      setError(true);
    } else {
      setError(false);
      setStep((prev) => prev + 1);
    }
  };

  const restartForm = () => {
    setSuccess(false);
    setStep(1);
  };

  useEffect(() => {
    if (questions[step]) {
      setItemForm(questions[step]);
      setProgress((100 / questions.length) * step);
      const firstOption = questions[step]?.select?.[0];

      if (firstOption) {
        setSelectValue(firstOption);
        setError(false);
      } else {
        setSelectValue("");
      }
      if (step === 1) {
        axiosValue = [];
        if (selectValue === "LAPTOP") {
          axiosValue.push("💻");
        } else if (selectValue === "PC") {
          axiosValue.push("🖥");
        }
      } else {
        axiosValue.push(inputValue ? inputValue : selectValue);
      }
    }
    setInputValue("");
  }, [step]);

  return (
    <Box className={cl.add_wrapper}>
      <Typography variant="h6">Write down your configuration</Typography>
      <Box className={cl.form_wrapper}>
        {itemForm && step <= questions.length - 1 && !success && (
          <Box className={cl.form}>
            <Typography sx={{ marginBottom: "10px" }}>
              {itemForm.text}
            </Typography>
            {itemForm.select ? (
              <Select
                labelId="select"
                id="select"
                value={selectValue}
                onChange={(e) => setSelectValue(e.target.value)}
                sx={{ width: "100%" }}
              >
                {itemForm.select.map((elem: any) => (
                  <MenuItem value={elem}>{elem}</MenuItem>
                ))}
              </Select>
            ) : (
              <TextField
                sx={{ width: "100%" }}
                id="standard-basic"
                label={itemForm.label}
                variant="standard"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={itemForm.placeholder}
              />
            )}
          </Box>
        )}

        {success ? (
          <Alert variant="outlined" severity="success">
            {resultText}
          </Alert>
        ) : (
          <LinearProgressWithLabel value={progress} />
        )}
        {error && (
          <Alert variant="outlined" severity="error">
            Fill in the details
          </Alert>
        )}
      </Box>

      {!success ? (
        <Box className={cl.buttons}>
          {step > 1 && (
            <Button onClick={() => setStep((prev) => prev - 1)}>Prev</Button>
          )}
          {step < questions.length - 1 ? (
            <Button onClick={nextStep}>Next</Button>
          ) : (
            <Button color="success" onClick={sendForm}>
              Отправить
            </Button>
          )}
        </Box>
      ) : (
        <Button onClick={restartForm}>Restart</Button>
      )}
    </Box>
  );
};

export default AddResult;
