import { combineReducers } from "redux";
import inputReducer from "./input";
import menuReducer from "./menu";
import loadingReducer from "./lodaing";

const rootReducer = combineReducers({
  input: inputReducer,
  menu: menuReducer,
  loading: loadingReducer
});

export default rootReducer;
