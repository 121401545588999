import React, { useState } from "react";
import "./Menu.scss";
import { Box, Grid, Typography } from "@mui/material";
import FireIcon from "../icons/FireIcon";
import DialIcon from "../icons/DialIcon";
import PersonIcon from "../icons/PersonIcon";
import PencilIcon from "../icons/PencilIcon";
import { useDispatch } from "react-redux";
import { updateMenu } from "../redux/actions/menu";

const MenuBottom = () => {
  const dispatch = useDispatch();
  const [iconActive, setIconActive] = useState<string>("rating");
  const selectIcon = (num: string) => {
    setIconActive(num);
    dispatch(updateMenu(num));
  };

  return (
    <Grid item xs={12} className="Menu">
      <Box
        className={
          iconActive === "profile" ? "icon_wrapper active" : "icon_wrapper"
        }
        onClick={() => selectIcon("profile")}
      >
        <PersonIcon />
        <Typography>My profile</Typography>
      </Box>
      <Box
        className={
          iconActive === "rating" ? "icon_wrapper active" : "icon_wrapper"
        }
        onClick={() => selectIcon("rating")}
      >
        <FireIcon />
        <Typography>Result</Typography>
      </Box>
      <Box
        className={
          iconActive === "add" ? "icon_wrapper active" : "icon_wrapper"
        }
        onClick={() => selectIcon("add")}
      >
        <PencilIcon />
        <Typography>Add</Typography>
      </Box>

      <Box
        className={
          iconActive === "info" ? "icon_wrapper active" : "icon_wrapper"
        }
        onClick={() => selectIcon("info")}
      >
        <DialIcon />
        <Typography>About</Typography>
      </Box>
    </Grid>
  );
};

export default MenuBottom;
