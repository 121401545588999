import { SvgIcon } from "@mui/material";
import React from "react";

const WindowsIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="3.42868" height="3.42868" fill="black" />
        <rect x="4.57227" width="3.42868" height="3.42868" fill="black" />
        <rect
          x="4.57227"
          y="4.57153"
          width="3.42868"
          height="3.42868"
          fill="black"
        />
        <rect y="4.57153" width="3.42868" height="3.42868" fill="black" />
      </svg>
    </SvgIcon>
  );
};

export default WindowsIcon;
