import { SvgIcon } from "@mui/material";
import React from "react";

const DialIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="22"
        height="17"
        viewBox="0 0 22 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8324 0.352539C5.0357 0.352539 0.292969 5.09527 0.292969 10.8919H2.92782C2.92782 6.51809 6.45852 2.98739 10.8324 2.98739C15.2062 2.98739 18.7369 6.51809 18.7369 10.8919H21.3718C21.3718 5.09527 16.629 0.352539 10.8324 0.352539ZM9.27781 5.85938C7.14358 6.51809 5.56267 8.52058 5.56267 10.8919C5.56267 13.8166 7.93404 16.1616 10.8324 16.1616C13.7571 16.1616 16.1021 13.8166 16.1021 10.8919C16.1021 8.52058 14.5475 6.54444 12.3869 5.85938L10.8324 8.17805L9.27781 5.85938Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default DialIcon;
