import { SvgIcon } from "@mui/material";
import React from "react";

const MacIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.83233 7.01373C7.6887 7.32817 7.51869 7.6176 7.32171 7.88371C7.0532 8.24647 6.83336 8.49758 6.66393 8.63702C6.40129 8.8659 6.11989 8.98312 5.81856 8.98978C5.60223 8.98978 5.34135 8.93145 5.03767 8.81312C4.73299 8.69535 4.453 8.63702 4.19698 8.63702C3.92848 8.63702 3.64051 8.69535 3.33249 8.81312C3.02401 8.93145 2.77549 8.99312 2.58549 8.99923C2.29653 9.01089 2.0085 8.89034 1.721 8.63702C1.53751 8.48535 1.30799 8.22536 1.03304 7.85704C0.738033 7.46372 0.495501 7.00762 0.305496 6.48764C0.102008 5.92599 0 5.38211 0 4.85558C0 4.25243 0.137535 3.73222 0.413015 3.29629C0.629518 2.94613 0.917544 2.66992 1.27803 2.46715C1.63852 2.26438 2.02802 2.16105 2.44749 2.15444C2.677 2.15444 2.97799 2.22171 3.35202 2.35393C3.72499 2.48659 3.96447 2.55387 4.06947 2.55387C4.14797 2.55387 4.41401 2.4752 4.86502 2.31838C5.29151 2.17294 5.65147 2.11271 5.94636 2.13644C6.74542 2.19755 7.34574 2.49604 7.74498 3.0338C7.03034 3.44412 6.67683 4.01882 6.68386 4.75608C6.69031 5.33034 6.91016 5.80821 7.34223 6.18765C7.53803 6.36375 7.75671 6.49986 8 6.59652C7.94724 6.74152 7.89154 6.8804 7.83233 7.01373ZM5.99971 0.180051C5.99971 0.630154 5.82618 1.05041 5.48029 1.4394C5.06288 1.90183 4.558 2.16905 4.01049 2.12688C4.00352 2.07288 3.99947 2.01605 3.99947 1.95633C3.99947 1.52423 4.19798 1.0618 4.55049 0.683702C4.72648 0.492264 4.95032 0.333085 5.22175 0.206106C5.4926 0.0810206 5.74879 0.0118459 5.98974 0C5.99678 0.0601717 5.99971 0.120353 5.99971 0.180051Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default MacIcon;
