import React, { useState } from "react";
// @ts-ignore
import logo from "../../../assets/img/logo.jpg";

import {
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Typography,
  Box,
  Button,
  List,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const text_about = {
  en: {
    desc_1: `SHOCK BENCH AE - is a modern and technological benchmark project
   created for After Effects. Our project uses scenes to test the GPU,
   CPU, SSD and RAM speed, processor multi-threading, amount of RAM and
   disk cache size.`,
    desc_2: `Method:`,
    desc_3: `The testing process occurs in three stages:`,
    desc_3_1: `Pre-rendering of the
  animation sequence in PNG format with an alpha channel. `,
    desc_3_2: `Automatic
  import of the rendered sequence with replacement of the same scene.`,
    desc_3_3: `Rendering the main scene, from which you can determine the final
  rendering time.`,
    desc_4: `The project consists of 6 different and unique scenes created
  specifically for testing.`,
    desc_5: `Before you start testing, you need to check the initial settings of
  your After Effect.`,
    desc_6: `Initial settings After Effects`,
    desc_6_1: `Set minimum cache size: 80 GB`,
    desc_6_2: `Set the minimum value for the use of RAM for other applications
  in the "memory and performance" section`,
    desc_6_3: `Enable multi-frame rendering and minimum value for reserved for
  other applications`,
    desc_6_4: `In the project settings, select the GPU renderer suitable for
  your platform: Mac OS - OpenCL/Metal, Windows - OpenCL/CUDA`,
  },

  ru: {
    desc_1: `SHOCK BENCH AE - это современный и технологический эталонный проект, созданный для After Effects.
`,
    desc_2: `Наш проект использует сцены для тестирования скорости GPU, CPU, SSD и RAM, многопоточности процессора, объема оперативной памяти и размера кэша диска.
    `,
    desc_3: `Процесс тестирования проходит в три этапа:`,
    desc_3_1: `Предварительный рендеринг последовательности анимации в формате PNG с альфа-каналом.
    `,
    desc_3_2: `Автоматический импорт отрисованной последовательности с заменой той же сцены.
    `,
    desc_3_3: `Рендеринг главной сцены, из которой вы можете определить окончательное время рендеринга.
    `,
    desc_4: `Проект состоит из 6 различных и уникальных сцен, созданных специально для тестирования.
    `,
    desc_5: `Прежде чем начать тестирование, вам необходимо проверить начальные настройки After Effect.
    `,
    desc_6: `Начальные настройки After Effects
    `,
    desc_6_1: `Установить минимальный размер кэша: 80 ГБ
    `,
    desc_6_2: `Установите минимальное значение для использования оперативной памяти для других приложений в разделе "Memory & Perfomance"
    `,
    desc_6_3: `Включить многокадровый рендеринг и минимальное значение для зарезервированного для других приложений
    `,
    desc_6_4: `В настройках проекта выберите рендеринг GPU, подходящий для вашей платформы: Mac OS - OpenCL/Metal, Windows - OpenCL/CUDA`,
  },
};

const InfoView = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [local, setLocal] = useState(text_about.en);
  const [lang, setLang] = useState<string | "en" | "ru">("en");

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleChangeLang = (text: string) => {
    setLang(text);
    if (text === "en") {
      setLocal(text_about.en);
    } else {
      setLocal(text_about.ru);
    }
  };

  return (
    <Card sx={{ maxWidth: "100wh", maxHeight: "70vh", overflow: "auto" }}>
      <CardHeader title="SHOCK BENCH AE" subheader="September 14, 2023" />
      <CardMedia
        component="img"
        height="194"
        image={logo}
        alt="SHOCK BENCH LOGO"
      />
      <CardContent>
        <Typography>{local.desc_1}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Box>
          <Button
            variant="contained"
            sx={{ backgroundColor: lang === "en" ? "" : "gray" }}
            onClick={() => handleChangeLang("en")}
          >
            EN
          </Button>
          <Button
            variant="contained"
            sx={{
              marginLeft: "5px",
              backgroundColor: lang === "ru" ? "" : "gray",
            }}
            onClick={() => handleChangeLang("ru")}
          >
            RU
          </Button>
        </Box>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{local.desc_2}</Typography>
          <Typography paragraph>{local.desc_3}</Typography>
          <List sx={{ paddingLeft: "20px" }}>
            <ol>
              <li>{local.desc_3_1}</li>
              <li>{local.desc_3_2}</li>
              <li>{local.desc_3_3}</li>
            </ol>
          </List>
          <Typography paragraph>{local.desc_4}</Typography>
          <Typography paragraph>{local.desc_5}</Typography>
          <Typography paragraph> {local.desc_6}</Typography>
          <List sx={{ paddingLeft: "20px" }}>
            <ol>
              <li>{local.desc_6_1}</li>
              <li>{local.desc_6_2}</li>
              <li>{local.desc_6_3}</li>
              <li>{local.desc_6_4}</li>
            </ol>
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default InfoView;
