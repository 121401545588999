import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// @ts-ignore
import cl from "./Profile.module.scss";
import RatingCard from "../rating/ratingCard/RatingCard";
import { card_pc } from "../../utils/types/card";
import axios from "axios";
//@ts-ignore
const tg = window.Telegram?.WebApp;

const apiKey = process.env.REACT_APP_API_KEY;

const Profile = () => {
  const [item, setItem] = useState<card_pc | null>(null);

  useEffect(() => {
    const id = tg?.initDataUnsafe?.user?.id;
    if (id) {
      axios
        .get(apiKey + `?chat_id=${id}`)
        .then((res) => {
          if (res) {
            let data = res.data;
            setItem(data);
          }
        })
        .catch((e) => {
          console.log(e);
          return;
        });
    }
  }, []);

  return (
    <Box className={cl.profile}>
      <Box className={cl.profile_title}>
        <Typography variant="h5">Profile</Typography>
      </Box>
      <Typography variant="h6">My configuration</Typography>
      {item ? (
        <RatingCard item={item} />
      ) : (
        <Box>
          <Typography >No result found</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Profile;
