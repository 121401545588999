import { UPDATE_MENU } from '../actions/menu';

interface InputState {
  value: string;
}

const initialState: InputState = {
  value: 'rating',
};

const menuReducer = (state: InputState = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_MENU:
      return { ...state, value: action.payload };
    default:
      return state;
  }
};

export default menuReducer;