import { SvgIcon } from "@mui/material";
import React from "react";

const PencilIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8091 0L13.1743 2.63485L18.444 7.90455L21.0788 5.2697L15.8091 0ZM10.5394 5.2697L0 15.8091V21.0788H5.2697L15.8091 10.5394L10.5394 5.2697Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default PencilIcon;
