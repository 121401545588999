import { SvgIcon } from "@mui/material";
import React from "react";

const FireIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="22"
        height="25"
        viewBox="0 0 22 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.02252 0C9.03378 6.02252 0 9.03378 0 15.0563C0 21.0788 6.02252 24.0901 6.02252 24.0901C3.07148 18.1278 12.045 15.0563 12.045 9.03378C12.045 3.01126 6.02252 0 6.02252 0ZM15.0563 9.03378C18.0676 15.0563 9.03378 18.0676 9.03378 24.0901H18.0676C19.2721 24.0901 21.0788 22.5844 21.0788 18.0676C21.0788 12.045 15.0563 9.03378 15.0563 9.03378Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default FireIcon;
