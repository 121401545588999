import { UPDATE_LOADING } from '../actions/loading';

interface state {
  value: boolean;
}

const initialState: state = {
  value: false,
};

const loadingReducer = (state: state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, value: action.payload };
    default:
      return state;
  }
};

export default loadingReducer;